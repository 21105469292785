import { lazy } from 'react';

// project imports
import MainLayout from 'template/layout/MainLayout';
import Loadable from 'template/ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard')));
const DashboardSettingPage = Loadable(lazy(() => import('../views/dashboard/setting')));
const UtilityHistory = Loadable(lazy(() => import('../views/utilities/history')));
const CustomPage = Loadable(lazy(() => import('../views/utilities/custom')));
const WarningPage = Loadable(lazy(() => import('../views/utilities/warning')));
const UserManualPage = Loadable(lazy(() => import('../views/help/userManual')));
const HelpCenterPage = Loadable(lazy(() => import('../views/help')));
const DemoG7Page = Loadable(lazy(() => import('../views/demo/g7')));
const LeavePage = Loadable(lazy(() => import('../views/help/leave')));
const CaseListPage = Loadable(lazy(() => import('../views/case')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/eh',
    element: <MainLayout />,
    children: [
        {
            path: '/eh',
            element: <DashboardDefault />
        },
        {
            path: '/eh/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/eh/dashboard/setting',
            element: <DashboardSettingPage />
        },
        {
            path: '/eh/utilities/history',
            element: <UtilityHistory />
        },
        {
            path: '/eh/utilities/custom',
            element: <CustomPage />
        },
        {
            path: '/eh/utilities/warning',
            element: <WarningPage />
        },
        {
            path: '/eh/utilities/userManual',
            element: <UserManualPage />
        },
        {
            path: '/eh/help/home',
            element: <HelpCenterPage />
        },
        {
            path: '/eh/demo/g7',
            element: <DemoG7Page />
        },
        {
            path: '/eh/leave',
            element: <LeavePage />
        },
        {
            path: '/eh/case/list',
            element: <CaseListPage />
        },
    ]
};

export default MainRoutes;
