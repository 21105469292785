import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    Dialog,
    useMediaQuery,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalStorage } from 'usehooks-ts';

// project imports
import MainCard from 'template/ui-component/cards/MainCard';
import Transitions from 'template/ui-component/extended/Transitions';
import { changeLocales } from 'store/commonSlice';
import { useAppSelector } from 'app/hooks';


// assets
import { IconWorld } from '@tabler/icons';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { height, width } from '@mui/system';

// notification status options
const status = [
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'zh',
        label: '中文'
    },
    {
        value: 'jp',
        label: '日本語'
    },
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const lang = useAppSelector((state) => state.common.language);
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [lsLang, setLsLang] = useLocalStorage('lang', 'zh');

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(lang);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setValue(lsLang);
    }, []);

    const handleChange = (event) => {
        const value = event?.target.value
        if (value) {
            setValue(value);
            setLsLang(value);
            dispatch(changeLocales(value));
            i18n.changeLanguage(value);
        }
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: isMobile?theme.palette.primary.main : theme.palette.secondary.light,
                            color: isMobile?theme.palette.secondary.light:theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            },
                            height: isMobile? '40px':'34px',
                            width: isMobile? '40px':'34px'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconWorld stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Dialog open={true}><DialogContent><Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                {/* <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}> */}
                                <MainCard border={false} content={false}>                                
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="h4">{t('title_lang')}</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box sx={{ px: 2, pt: 0.25 }}>
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                select
                                                                fullWidth
                                                                value={value}
                                                                onChange={handleChange}
                                                                SelectProps={{
                                                                    native: true
                                                                }}
                                                                color='secondary'
                                                                // size='small'   
                                                                sx={{ minWidth: '200px' }}
                                                            >
                                                                {status.map((option) => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </TextField>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} p={0}>
                                                        <Divider sx={{ my: 0 }} />
                                                    </Grid>
                                                </Grid>
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                    {/* <Divider /> */}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper></DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>OK</Button>
                            </DialogActions>
                        </Dialog>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
