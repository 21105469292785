import { Dashboard, EditNotifications, SettingsSuggest, SvgIconComponent } from '@mui/icons-material';
import { ButtonBase, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const SettingItem = (props: { title: string, svg: SvgIconComponent, uri: string }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const onClick = () => {
        navigate(props.uri);
    }
    return (
        <Grid item>
            <ButtonBase sx={{ border: '1px solid', p: '8px', borderColor: theme.palette.primary.main }}
                onClick={onClick}>
                <Stack alignItems={'center'} spacing={1}>
                    <props.svg color='primary' fontSize='large' />
                    <Typography variant='caption' sx={{ textAlign: 'center', minWidth: '45px', maxWidth: '215px' }}>{props.title}</Typography>
                </Stack>
            </ButtonBase>
        </Grid>
    )
}

const SettingBox = () => {
    const { t } = useTranslation();

    return (
        <Box sx={{ p: isMobile ? '10px' : 0, maxWidth: '215px' }}>
            <Typography variant="h4" sx={{ mb: '16px' }}>{t('title_setting')}</Typography>
            <Grid container spacing={1}>
                <SettingItem title={t('menu_custom')} svg={SettingsSuggest} uri='/eh/utilities/custom'></SettingItem>
                <SettingItem title={t('title_dashboardOption')} svg={Dashboard} uri='/eh/dashboard/setting'></SettingItem>
                <SettingItem title={t('menu_warning')} svg={EditNotifications} uri='/eh/utilities/warning'></SettingItem>
            </Grid>
        </Box>
    );
};

export default SettingBox;
