// assets
import { IconDashboard } from '@tabler/icons';
import StoreIcon from '@mui/icons-material/Store';
import MenuItem from './if';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard:MenuItem = {
    id: 'dashboard',
    title: 'menu_dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'menu_dashboard',
            type: 'item',
            url: '/eh/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'history',
            title: 'menu_history',
            type: 'item',
            url: '/eh/utilities/history',
            icon: StoreIcon,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
