import i18n from 'i18next';
import {initReactI18next} from 'react-i18next'
import enLocal from '../assets/language/en.json';
import zhLocal from '../assets/language/zh.json';
import jpLocal from '../assets/language/jp.json';

const resources = {
  en: {translation:enLocal},
  zh: {translation:zhLocal},
  jp: {translation:jpLocal},
}

i18n.use(initReactI18next)
.init({
  resources,
  lng: 'zh',
  fallbackLng: 'zh',
  interpolation:{
    escapeValue: false, // react already safes from xss
  }
});

export default i18n;
