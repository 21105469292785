import { useState, useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Chip,
    ClickAwayListener,
    Paper,
    Popper} from '@mui/material';

// third-party
import { useTranslation } from 'react-i18next';

// project imports
import MainCard from 'template/ui-component/cards/MainCard';
import Transitions from 'template/ui-component/extended/Transitions';
import { changeSettings } from 'store/sensorSlice';
// assets
import { IconSettings } from '@tabler/icons';
import AboutSection from 'ui/componnet/AboutPanel';
import { useLocation } from 'react-router-dom';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [hideEnv, setHideEnv] = useState(false);
    const [hideDoor, setHideDoor] = useState(false);
    const [hideId, setHideId] = useState(false);

    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.sensor.setting);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        dispatch(changeSettings({ hideEnv: hideEnv, hideDoor: hideDoor, hideId: hideId }));
    }, [hideEnv, hideDoor, hideId]);

    useEffect(() => {
        setHideEnv(settings.hideEnv);
        setHideDoor(settings.hideDoor);
        setHideId(settings.hideId);
    }, []);

    const location = useLocation();
    
    useEffect(() => {
        setOpen(false);
    }, [location]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <AboutSection></AboutSection>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
