import { gql } from '@apollo/client'


export const Q_APP_INFO = gql`
      query GetSensors { 
      appInfo{
            version
            description  
            name
            time
      }
}`;

