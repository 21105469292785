import caseList from './case';
import dashboard from './dashboard';
import demo from './demo';
import helps from './help';
import { MenuItems } from './if';
import utilities from './utility';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: MenuItems = {
    items: [dashboard, demo, caseList, helps]
};

export default menuItems;
