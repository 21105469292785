const configDev = {
    http: 'http://',
    ws: 'ws://',
    apiHost: 'localhost:3000',
    // apiHost: 'eh.ricoh-rits.com',
}

const configProd = {
    http: 'https://',
    ws: 'wss://',
    apiHost: 'eh.ricoh-rits.com',
}

const configFn = () => {
    const env = process.env.NODE_ENV;
    if(env === 'production'){
        return configProd;
    }else{
        return configDev;
    }
};

const configOrg = configFn();

const config = {
    origin: configOrg,
    httpBase: configOrg.http + configOrg.apiHost,
    wsBase: configOrg.ws + configOrg.apiHost,
}

export default config;
