import { ApolloClient, gql } from '@apollo/client'
import { TimeEnum } from 'template/utils/common';

export async function fetchAll(): Promise<string[]> {
      //const res = 
      return [];
}

export function init() {
      //return new ApolloClient({ uri: GQLURI, cache: new InMemoryCache() });
}

export const Q_SENSORS = gql`
      query GetSensors { 
      sensors{
            id
            name
            type
      }
}`;

export const Q_RECORD = gql`
      query GetSensorRecord($name:String!, $page:Int, $type:Int!) { 
      sensorRecord(args:{name:$name, page:$page, type:$type}){
            humidity  
            illum
            mac
            name
            pressure
            recordTime
            rss
            temp
            voltage
            state
            action
            count
      }
}`;

export const Q_RECORD_COUNT = gql`
      query GetSensorRecordCount($name:String!, $type:Int!) { 
      sensorRecordCount(name:$name, type:$type)
}
`;

export const S_RECORD = gql`
      subscription OnSensorRecordAdded{ 
      recordCreated {
            type
            data {
                  id
                  humidity
                  illum
                  mac
                  name
                  pressure
                  recordTime
                  rss
                  temp
                  voltage
                  state
                  action
                  count
                  x
                  y
                  z
                  iaq
                  iaqAcc
                  iaqIndoor
                  iaqIndoorAcc
                  co2
                  co2Acc
                  voc
                  vocAcc
                  region
            }
      }
}
`;

export const Q_HISTORY_BOUND = gql`
      query sensorHistoryBound($name:String!, $type:Int!) { 
            sensorHistoryBound(args:{name:$name, type:$type}){
            recordTime            
      }
}
`;

export const Q_HISTORY = gql`
      query GetSensorHistory($name:String!, $span:Int!, $from:Date!, $to:Date!, $type:Int!) { 
      sensorHistory(args:{name:$name, span:$span, from:$from, to:$to, type:$type}){
            humidity  
            illum
            mac
            name
            pressure
            recordTime
            rss
            temp
            voltage
            state
            action
            count
            x
            y
            z
            iaq
            iaqAcc
            iaqIndoor
            iaqIndoorAcc
            co2
            co2Acc
            voc
            vocAcc
      }
}`;

export const Q_RECORD_CACHE = gql`
      query GetRecordCache($names: [String!]!){
      recordCache(args: {names: $names}) {
            data {
                  id
                  humidity
                  illum
                  mac
                  name
                  pressure
                  recordTime
                  rss
                  temp
                  voltage
                  action
                  count
                  state
                  x
                  y
                  z
                  iaq
                  iaqAcc
                  iaqIndoor
                  iaqIndoorAcc
                  co2
                  co2Acc
                  voc
                  vocAcc
                  region
            }
            type
      }
}
`;

export const Q_CSV_EXPORT = gql`
      query ExportCsv($param:QueryCsvInput!) { 
            recordToCsv(args:$param)
}
`;

export const Q_WARNING_SETTINGS = gql`
      query GetWarningSettings{ 
      warningSettings{
            doorChange
            humMax
            humMaxFlag
            humMin
            humMinFlag
            id
            illumMax
            illumMaxFlag
            illumMin
            illumMinFlag
            internal
            isOn
            mail
            name
            pressMax
            pressMaxFlag
            pressMin
            pressMinFlag
            sensors
            tempMax
            tempMaxFlag
            tempMin
            tempMinFlag
            vccMax
            vccMaxFlag
            vccMin
            vccMinFlag
            weChat
      }
}`;

export const M_WARNING_SETTINGS = gql`
      mutation SetWarnings($param:UpdateWarningSetting!) { 
            updateWarning(data:$param)
}
`;

// ================================== interface =================================
export interface ICsvParam {
      name: string,
      isAll: boolean,
      from: Date | null,
      to: Date | null,
      type: number,
}

export interface IWarningSetting {
      id: number,
      name?: string,
      isOn: boolean,
      doorChange: boolean,
      tempMaxFlag: boolean,
      tempMinFlag: boolean,
      tempMax: number,
      tempMin: number,
      humMaxFlag: boolean,
      humMinFlag: boolean,
      humMax: number,
      humMin: number,
      illumMaxFlag: boolean,
      illumMinFlag: boolean,
      illumMax: number,
      illumMin: number,
      pressMaxFlag: boolean,
      pressMinFlag: boolean,
      pressMax: number,
      pressMin: number,
      vccMaxFlag: boolean,
      vccMinFlag: boolean,
      vccMax: number,
      vccMin: number,
      mail: string[],
      internal: number,
      sensors: string[],
      weChat: string[],
}

// ================================== tool =================================
export const getTimeSpan = (div: TimeEnum): number => {
      let ret = 0;
      switch (div) {
            case TimeEnum.day:
                  ret = 5; //5 min
                  break;
            case TimeEnum.month:
                  ret = 1 * 60; //1 hour
                  break;
            case TimeEnum.year:
                  ret = 24 * 60; //1 day
                  break;

            default:
                  break;
      }
      return ret;
}
