import exp from "constants";

/*
 * span:  milliseconds
*/
export const isTimeUp = (time: string, span: number) => {
  try {
    const diff = Date.now() - new Date(time).getTime();
    return diff > span;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const isHourUp = (time: string) => {
  return isTimeUp(time, TimeSpan1Hour * 1000);
}

export const timeUpReschedule = (time: string, secondsSpan: number): Date => {
  const next = new Date(time).getTime() + secondsSpan * 1000;
  return new Date(next);
}

export const nextTime = (secondsSpan: number): Date => {
  const next = new Date().getTime() + secondsSpan * 1000;
  return new Date(next);
}

export const getDayOfTime = (time: string) => {
  return new Date(new Date(time).setHours(0, 0, 0, 0))
}

export const getDayEndOfTime = (time: string) => {
  return new Date(new Date(time).setHours(24, 0, 0, 0))
}

export enum TimeEnum {
  day, month, year, origin
}

export interface IPeriod {
  min: string;
  max: string;
  from: Date;
  to: Date;
  count: number;
  div: TimeEnum;
}

export const convertToPeroid = (min: string, max: string, div: TimeEnum): IPeriod => {
  const p = {
    min: min,
    max: max,
    count: 0,
    div: div,
  } as IPeriod;
  return changePeroidDiv(p,div);
}

export const changePeroidDiv = (p: IPeriod, div: TimeEnum): IPeriod => {
  const dayMin = getDayOfTime(p.min);
  const dayMax = getDayOfTime(p.max);
  const dayMaxEnd = getDayEndOfTime(p.max);

  let count = 0;
  let from = dayMax;
  if (div === TimeEnum.day) {
    const diff = dayMaxEnd.getTime() - dayMin.getTime();
    count = diff / (24 * 60 * 60 * 1000);
  } else if (div === TimeEnum.month) {
    count = (dayMaxEnd.getFullYear() - dayMin.getFullYear()) * 12 + dayMaxEnd.getMonth() - dayMin.getMonth() + 1;
    from = new Date(dayMax.setDate(1));
  } else if (div === TimeEnum.year) {
    count = dayMaxEnd.getFullYear() - dayMin.getFullYear() + 1;
    from = new Date(dayMax.setMonth(1,1));
  }

  return {
    min: p.min,
    max: p.max,
    from: from,
    to: dayMaxEnd,
    count: count,
    div: div,
  };
}

export const timePeriod = (p: IPeriod, diff: number): IPeriod => {
  const dayMax = getDayOfTime(p.max);
  const dayMaxEnd = getDayEndOfTime(p.max);
  let from = 0;
  let to = 0;
  if(p.div === TimeEnum.day){
    const diffTime = diff *24*60*60*1000;
     from = dayMax.getTime() + diffTime;
     to = dayMaxEnd.getTime() + diffTime;
  }else if(p.div === TimeEnum.month){    
     from = dayMax.setMonth(dayMax.getMonth()+diff,1);
     to = dayMax.setMonth(dayMax.getMonth()+1);
  }else if(p.div === TimeEnum.year){
    from = dayMax.setFullYear(dayMax.getFullYear()+diff,1,1);
    to = dayMax.setFullYear(dayMax.getFullYear()+1);
 }

  return {
    ...p,
    from: new Date(from),
    to: new Date(to)
  };
}

//===================== const ==========================/
export const TimeSpan1Hour = 60 * 60;
