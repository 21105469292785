import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

// material-ui
import { IconButton, Typography } from '@mui/material';

// project imports
import config from 'template/config';
import Logo from 'template/ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    return <IconButton color='primary' disableRipple component={Link} to={config.defaultPath}>
        {/* <Logo />
        <Typography variant="h3" style={{marginLeft: '8px'}}>EH Monitor</Typography> */}
        <img width='150px' height='48px' loading='lazy' src='/banner-202206-2.png' alt={<Typography variant="h3" style={{marginLeft: '8px'}}>EH Monitor</Typography>}></img>
    </IconButton>
    
};

export default LogoSection;
