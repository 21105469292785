import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import * as gql from './sensorAPI';
import { EnvDataWrpper, initialState, SensorTag, Setting } from './sensorState';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getSensorsAsync = createAsyncThunk(
  'sensor/fetchAll',
  async () => {
    const response = await gql.fetchAll();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sensorSlice = createSlice({
  name: 'sensor',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    clickSensor: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selectedSensor = action.payload;
    },

    initialGql: (state) =>{
      state.gqlClient = gql.init();
    },

    updateEnvData: (state, action: PayloadAction<EnvDataWrpper>) =>{
      state.envdata = action.payload;
    },
    
    changeSettings: (state, action: PayloadAction<Setting>) =>{
      state.setting = action.payload;
    },

    updateSensorTag:  (state, action: PayloadAction<SensorTag>) =>{
      state.sensorTag = action.payload;
    },

    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // .addCase(getSensorsAsync.pending, (state) => {
        
      // })
      .addCase(getSensorsAsync.fulfilled, (state, action) => {
        state.sensors = action.payload;
      });
  },
});

export const { clickSensor,updateEnvData,changeSettings,updateSensorTag } = sensorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSensor = (state: RootState) => state.sensor.selectedSensor;


export default sensorSlice.reducer;
