import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import customizationReducer from '../template/store/customizationReducer';
import sensorReducer from 'store/sensorSlice';
import commonReducer from 'store/commonSlice';

export const store = configureStore({
  reducer: combineReducers({
    customization: customizationReducer,
    sensor: sensorReducer,
    common: commonReducer,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
