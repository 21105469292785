import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  language: 'zh',
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeLocales: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.language = action.payload;
    },
  },
});

export const { changeLocales } = commonSlice.actions;

export default commonSlice.reducer;
