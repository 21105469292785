import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/system';
import NavigationScroll from './template/layout/NavigationScroll';
import themes from './template/themes';
import Routes from './ui/routes';
import { useAppSelector } from './app/hooks';
import { useEffect, useState } from 'react';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import i18n from 'app/lang';
import { useLocalStorage } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';

function App() {
  // const common = useAppSelector((state) => state.common);
  const customization = useAppSelector((state) => state.customization as any);
  const envData = useSelector((state: RootState) => state.sensor.envdata);
  const [lsLang] = useLocalStorage('lang', 'zh');
  const navigate = useNavigate();
  const [isLeave, setLeave] = useState(false);
  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setLeave(false);
    } else {
      setLeave(true);
    }
  }

  useEffect(() => {
    i18n.changeLanguage(lsLang);
    window.document.addEventListener('visibilitychange', onVisibilityChange, false);
  }, []);

  useEffect(() => {
    if (isLeave) {
      if (customization.title === 'menu_demo_g7' || customization.title === 'menu_dashboard') {
        navigate('/eh/leave');
      }
    } else {
      if (customization.title === 'menu_demo_g7') {
        navigate('/eh/demo/g7');
      } else if (customization.title === 'menu_dashboard') {
        navigate('/eh/dashboard/default');
      }
    }
  }, [isLeave]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
