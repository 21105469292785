
// material-ui
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
// third-party
import { useTranslation } from 'react-i18next';

// project imports
import { MenuOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { SET_MENU } from 'template/store/actions';
import NotificationSection from 'template/layout/MainLayout/Header/NotificationSection';
import AboutSection from './AboutSection';

const MobileToolBar = () => {
    const { t } = useTranslation();
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => (state as any).customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const title = useSelector((state) => (state as any).customization.title);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position='static' enableColorOnDark color='primary'>
                <Toolbar variant='dense' sx={{ minHeight: '48px' }}>
                <IconButton color='inherit'  onClick={handleLeftDrawerToggle}><MenuOutlined /></IconButton>                    
                    <Typography variant='h4' color={'inherit'} sx={{ pl: '16px', fontWeight: 500 }}>{t(title)}</Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <NotificationSection></NotificationSection>
                    <AboutSection />
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default MobileToolBar;
