// assets
import { HelpCenter } from '@mui/icons-material';
import MenuItem from './if';

// constant

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const helps:MenuItem = {
    id: 'help',
    title: 'menu_help',
    type: 'group',
    children: [
        {
            id: 'help_center',
            title: 'menu_help_home',
            type: 'item',
            url: '/eh/help/home',
            icon: HelpCenter,
            breadcrumbs: false
        },
    ]
};

export default helps;
