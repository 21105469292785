// assets
import { ViewCozyRounded } from '@mui/icons-material';
import MenuItem from './if';

// constant

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const demo:MenuItem = {
    id: 'demo',
    title: 'menu_demo',
    type: 'group',
    children: [
        {
            id: 'demo_g7',
            title: 'menu_demo_g7',
            type: 'item',
            url: '/eh/demo/g7',
            icon: ViewCozyRounded,
            breadcrumbs: false
        },
    ]
};

export default demo;
