import { WebSocketLink } from '@apollo/client/link/ws';
import { split, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities'
import config from 'config';

const GQL_PATH = `/graphql`;

const httpLink = new HttpLink({
  uri: `${config.httpBase}${GQL_PATH}`
});

const wsLink = new WebSocketLink({
  uri: `${config.wsBase}${GQL_PATH}`,
  options: { reconnect: true }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({ link: splitLink, cache: new InMemoryCache() });