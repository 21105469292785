export interface SensorState {
  sensors: string[];
  selectedSensor: string;
  gqlClient?: any;
  envdata: EnvDataWrpper;
  setting: Setting;
  sensorTag: SensorTag;
}

export interface EnvData {
  id: number;
  illum?: number;
  mac: string;
  name: string;
  pressure?: number;
  recordTime: string;
  rss: number;
  temp?: number;
  voltage: number;
  humidity?: number;
  state?: number;
  action?: number;
  count?: number;
  type?: number;
  x?: number;
  y?: number;
  z?: number;
  iaq?: number;
  iaqAcc?: number;
  iaqIndoor?: number;
  iaqIndoorAcc?: number;
  co2?: number;
  co2Acc?: number;
  voc?: number;
  vocAcc?: number;
  region?: string;
}
export interface EnvDataWrpper {
  internal: [string, EnvData][];
}

export interface Setting {
  hideEnv: boolean;
  hideDoor: boolean;
  hideId: boolean;
}

export interface SensorTag {
  tag: [string, string][];
}

export const initialState: SensorState = {
  sensors: [],
  selectedSensor: '',
  envdata: { internal: [] },
  setting: { hideDoor: false, hideEnv: false, hideId: true },
  sensorTag: { tag: [] },
};
