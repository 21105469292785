import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Drawer,
    IconButton} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

// project imports
import { isMobile } from 'react-device-detect';
import ProfileSection from 'template/layout/MainLayout/Header/ProfileSection';
import AboutPanel from './AboutPanel';
import { useLocation } from 'react-router-dom';

// ==============================|| PROFILE MENU ||============================== //

const AboutSection = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        setOpen(false);
    }, [location]);

    if (isMobile) {
        return (
            <>
                <IconButton color='inherit' onClick={() => { setOpen(true) }}><SettingsIcon /></IconButton>
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={() => setOpen(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <AboutPanel />
            </Drawer>
            </>
        );
    }

return (<ProfileSection />);
};

export default AboutSection;
