// assets
import { CasesRounded } from '@mui/icons-material';
import MenuItem from './if';

// constant

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const caseList:MenuItem = {
    id: 'case',
    title: 'menu_case_list',
    type: 'group',
    children: [
        {
            id: 'case_list',
            title: 'menu_case_list',
            type: 'item',
            url: '/eh/case/list',
            icon: CasesRounded,
            breadcrumbs: false
        },
    ]
};

export default caseList;
