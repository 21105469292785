import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Q_APP_INFO } from 'store/commonAPI';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    linearProgressClasses,
    Link, Tooltip, TooltipProps, tooltipClasses, Box, Stack
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import { ChatOutlined, MenuBookOutlined, AllInclusiveOutlined,Forward } from '@mui/icons-material';


// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: theme.palette.primary[800] }}>
                            Progress
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const theme = useTheme();
    const gqlResAppInfo = useQuery(Q_APP_INFO);
    const { t } = useTranslation();

    return (
        <CardStyle>
            <CardContent sx={{ p: 2 }}>
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0, pb: '8px' }}>
                        {/* <ListItemAvatar sx={{ mt: 0 }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    color: theme.palette.primary.main,
                                    border: 'none',
                                    borderColor: theme.palette.primary.main,
                                    background: '#fff',
                                    marginRight: '12px'
                                }}
                            >
                                <ChatOutlined fontSize="inherit" />
                            </Avatar>
                        </ListItemAvatar> */}
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                            {t('item_wechat')}</Typography></Grid><Grid item>
                                    </Grid>
                                </Grid>
                            }
                            secondary={
                                // <Grid container justifyContent='space-between'><Grid item>
                                //     <Link href={'/eh/utilities/userManual'}
                                //         target='_self' variant='body2'
                                //         underline='always'>{t('title_preview')}</Link></Grid>
                                //     <Grid item sx={{pr:'24px'}}>
                                //         <Link href={'http://47.99.205.146/download/EHSensorManual.pdf'}
                                //             target='_blank' variant='body2'
                                //             underline='always'>{t('title_download')}</Link></Grid>
                                // </Grid>
                                <Stack sx={{marginLeft: '16px'}} spacing={0}><Typography variant="caption">理光EH</Typography>
                                <img src="/ehrsc/qrcode_wechat.jpg" loading="lazy" width="140px" height="140px"></img>
                                </Stack>
                            }
                        />
                    </ListItem>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }} key='l1'>
                        <ListItemAvatar sx={{ mt: 0 }} key='l1_1'>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    color: theme.palette.primary.main,
                                    border: 'none',
                                    borderColor: theme.palette.primary.main,
                                    background: '#fff',
                                    marginRight: '12px'
                                }}
                            >
                                <TableChartOutlinedIcon fontSize="inherit" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                    {t('sidebar_mobile')}
                                </Typography>
                            }
                            secondary={<React.Fragment><Typography variant="caption">{`EH Android APP ${gqlResAppInfo.data?.appInfo.version}`}</Typography>
                                <br /><Link href={`/download/${gqlResAppInfo.data?.appInfo.name}`} target='_blank' rel='noopener' variant='body2' underline='always'>{t('title_download')}</Link>
                                <HtmlTooltip placement='right' title={
                                    <React.Fragment>
                                        <Typography color="inherit">{`${gqlResAppInfo.data?.appInfo.version} ${gqlResAppInfo.data?.appInfo.time}`}</Typography>
                                        <Box style={{ marginTop: 5 }}>
                                            <Typography color="inherit">{t('title_changeLog')}</Typography>
                                            {gqlResAppInfo.data?.appInfo.description.map((info, index) => { return (<p key={'info' + index}>{`- ${info}`}<br /></p>) })}
                                        </Box>
                                    </React.Fragment>
                                }>
                                    <IconButton>
                                        <InfoOutlinedIcon fontSize='small' />
                                    </IconButton>
                                </HtmlTooltip>
                            </React.Fragment>}
                        />
                    </ListItem>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }} key='l1'>
                        <ListItemAvatar sx={{ mt: 0 }} key='l1_1'>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    color: theme.palette.primary.main,
                                    border: 'none',
                                    borderColor: theme.palette.primary.main,
                                    background: '#fff',
                                    marginRight: '12px'
                                }}
                            >
                                <AllInclusiveOutlined fontSize="inherit" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                    {t('title_more')}
                                </Typography>
                            }
                            secondary={<React.Fragment>
                              <Link href={`https://eh.ricoh-rits.com/eh-cloud`} target='_blank' rel='noopener' variant='body2' underline='always'><Stack direction='row' spacing={2}><Forward /><Typography variant="caption">{`EH Cloud`}</Typography></Stack></Link>
                              <Link href={`https://eh.ricoh-rits.com/eh-cloud/apps/cards`} target='_blank' rel='noopener' variant='body2' underline='always'><Stack direction='row' spacing={2}><Forward /><Typography variant="caption">{t('link_app_cards')}</Typography></Stack></Link>
                            </React.Fragment>}
                        />
                    </ListItem>
                </List>
                {/* <LinearProgressWithLabel value={80} /> */}
            </CardContent>
        </CardStyle >
    );
};

export default MenuCard;
