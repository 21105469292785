import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Switch,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

// project imports
// import MainCard from 'template/ui-component/cards/MainCard';
import { changeSettings } from 'store/sensorSlice';
// assets
import UpgradePlanCard, { CardStyle } from 'template/layout/MainLayout/Header/ProfileSection/UpgradePlanCard';
import { isMobile } from 'react-device-detect';
import SettingBox from './SettingBox';

// ==============================|| PROFILE MENU ||============================== //

const AboutPanel = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [hideEnv, setHideEnv] = useState(false);
    const [hideDoor, setHideDoor] = useState(false);
    const [hideId, setHideId] = useState(false);
    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.sensor.setting);

    const scrollStyle: React.CSSProperties = isMobile ? { height: '100vh', overflowX: 'hidden' } :
        { height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' };

    useEffect(() => {
        dispatch(changeSettings({ hideEnv: hideEnv, hideDoor: hideDoor, hideId: hideId }));
    }, [hideEnv, hideDoor, hideId]);

    useEffect(() => {
        setHideEnv(settings.hideEnv);
        setHideDoor(settings.hideDoor);
        setHideId(settings.hideId);
    }, []);

    return (
        <>
            <PerfectScrollbar style={scrollStyle} options={{ suppressScrollX: true }}>
                <Box sx={{ p: 2 }}>
                    <UpgradePlanCard />
                    <Divider />
                    <Card
                        sx={{
                            bgcolor: theme.palette.primary.light,
                            my: 2
                        }}
                    >
                        {/* <CardContent>
                            <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                    <Typography variant="h4">{t('title_dashboardOption')}</Typography>
                                </Grid>
                                {!isMobile ? <>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography variant="subtitle2">{t('title_sensorType')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid item container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="body2">{t('option_hideEnv')}</Typography>
                                            </Grid>
                                            <Grid item sx={{ ml: 3 }}>
                                                <Switch
                                                    color="primary"
                                                    checked={hideEnv}
                                                    onChange={(e) => setHideEnv(e.target.checked)}
                                                    name="sdm"
                                                    size="small"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid item container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="body2">{t('option_hideDoor')}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Switch
                                                    checked={hideDoor}
                                                    onChange={(e) => setHideDoor(e.target.checked)}
                                                    name="sdm"
                                                    size="small"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid></>
                                    : <></>}
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2">{t('title_sensorItem')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid item container alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body2">{t('option_hideSId')}</Typography>
                                        </Grid>
                                        <Grid item sx={{ ml: 3 }}>
                                            <Switch
                                                color="primary"
                                                checked={hideId}
                                                onChange={(e) => setHideId(e.target.checked)}
                                                name="sdm"
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent> */}
                        <CardContent>
                            <SettingBox></SettingBox>
                        </CardContent>
                    </Card>
                    <Divider />
                    <CardStyle>
                        <CardContent>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography variant="h4">{t('title_about')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                                        Ver. 1.0
                                    </Typography>
                                </Grid>
                            </Grid>
                            </CardContent>
                    </CardStyle>
                    {/* <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                            <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List> */}
                </Box>
            </PerfectScrollbar>
        </>
    );
};

export default AboutPanel;
